<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>All Offer</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter">
        <template v-slot:document_no_="{ item }">
          <router-link :to="'/purchasing/offer/detail?id=' + item.id">{{ item.document_no_ }}</router-link>
        </template>
        <template v-slot:tender_document_no_="{ item }">
          <router-link :to="'/purchasing/tender/detail?id=' + item.tender_header_id">{{ item.tender_document_no_ }}</router-link>
        </template>

        <template v-slot:status="{ item }">
          <div class="text-center">
            <app-document-status v-model="item.status"></app-document-status>
          </div>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "tender/offer/data",
        headers: [
          { text: "Document No.", value: "document_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Document Version", value: "document_version", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Vendor Name", value: "vendor_name", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Vendor Category", value: "vendor_category", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Vendor Phone No.", value: "vendor_phone_no	", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Tender No.", value: "tender_document_no_", class: "text-no-wrap", cellClass: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap text-center", cellClass: "text-center text-no-wrap" },
        ],
        filter: {
          status: this.status
        }
      },
    };
  },
  watch: {
    status(val) {
      this.datatable.filter.status = val;
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
};
</script>
