<template>
  <div>
    <v-card flat elevation="0" class="">
      <v-card-title>All Tender</v-card-title>
      <v-divider></v-divider>
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter">
        <template v-slot:document_no_="{ item }">
          <router-link :to="'/purchasing/tender/detail?id=' + item.id">{{ item.document_no_ }}</router-link>
        </template>

        <template v-slot:published="{ item }">
          <div>
            <v-icon v-if="item.published" color="green">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </div>
        </template>

        <template v-slot:status="{ item }">
          <div>
            <app-document-status v-model="item.status"></app-document-status>
          </div>
        </template>
      </app-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "tender/data",
        headers: [
          { text: "Document No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Source Doc.", value: "source_document", class: "text-no-wrap" },
          { text: "Published", value: "published", class: "text-no-wrap" },
          { text: "Max. Vendor", value: "max_vendor", class: "text-no-wrap" },
          { text: "Due Date", value: "due_date", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
        ],
        filter: {
          status: this.status
        }
      },
    };
  },
  watch: {
    status(val) {
      this.datatable.filter.status = val;
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
};
</script>
