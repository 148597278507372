<template>
  <div>
    <v-container>
      <page-title title="Tender">
        <template slot="action">
          <v-btn color="primary" @click="dialog = true">Add</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>All</v-tab>
              <v-tab>Offer</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
              <v-tab-item>
                <ListDataOffer></ListDataOffer>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="450px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>Create New</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-select v-model="form_data.document_type" :error="form_error.document_type" outlined :items="types" title="Create New Tender"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="createDocument()">Create</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
import ListDataOffer from "./components/ListDataOffer.vue";
export default {
  components: {
    ListData,
    ListDataOffer,
  },
  data() {
    return {
      tab: this.$store.state.activeTab['Purchasing.Tender.Index'],
      dialog: null,

      form_data: {
        type: '',
      },

      form_error: {
        type: null,
      },

      types: [
        'Project',
        'Non-Project'
      ],

      valid: true,
    };
  },

  watch: {
    tab(newVal) {
      this.$store.state.activeTab['Purchasing.Tender.Index'] = newVal
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async createDocument() {
      this.showLoadingOverlay(true);
      var uri = '/tender/create'
      const formData = new FormData();
      formData.append("document_type", this.form_data.document_type);
      await this.$axios.post(uri, formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == 'success') {
            this.redirect('Purchasing.Tender.Edit', { id: res.data.data.tender.id });
            return;
          }
          this.showAlert(res.data.status, res.data.message);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.form_error = error.response.data.data.errors
        });
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("tender", "view", true);
  },
};
</script>
